/*
Template Name: Nazox -  Admin & Dashboard Template
Author: Themesdesign
Version: 2.0.0
Contact: themesdesign.in@gmail.com
File: Main Css File
*/


//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/color-picker";
@import "custom/plugins/session-timeout";
@import "custom/plugins/round-slider";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/switch";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/datatable";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/x-editable";

// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/chat";
@import "custom/pages/kanbanboard";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";


// Lexmark Custom Styles
.btn-primary-lexmark {
    //background-color: $primary;
    //border-color: $primary;
    //color: $white;
    color: #fff !important;
    background-color: #008945;
    border: 1px solid #006e37;
    border-bottom: 0;
    white-space: normal;
    -moz-box-shadow: #006734 0 3px 0;
    -webkit-box-shadow: #006734 0 3px 0;
    box-shadow: #006734 0 3px 0;
}

.btn-danger-lexmark {
    //background-color: $primary;
    //border-color: $primary;
    //color: $white;
    color: #fff !important;
    background-color: #b91d2d;
    border: 1px solid #a00010;
    border-bottom: 0;
    white-space: normal;
    -moz-box-shadow: #a00010 0 3px 0;
    -webkit-box-shadow: #a00010 0 3px 0;
    box-shadow: #a00010 0 3px 0;
}

.color-primary-lexmark {
    background-color: #008945;
}

.carousel-lexmark {
    background-color: white;
    padding: 50px;
}

.btn-second-lexmark {
    color: #fff !important;
    background-color: #6c757d;
    border: 1px solid #6c757d;
    border-bottom: 0;
    white-space: normal;
    -moz-box-shadow: #51575d 0 3px 0;
    -webkit-box-shadow: #51575d 0 3px 0;
    box-shadow: #51575d 0 3px 0;
}

.btn-download-lexmark {
    background-color: rgb(58, 242, 58) 0 3px 0;
    color: #fff !important;
}

/*
.icon-eyes: {
    color: #008945;
}
*/

.carousel-caption {
    margin-bottom: 100;
    background-color: white;
}

.carousel-login{
    height: 100vh;
    object-fit: cover;
}