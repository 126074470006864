@import "../../assets/scss/theme.scss";

.apexcharts-canvas {
  text {
    fill: $text-muted !important;
  }
}

.h-carousel{
  height: 50vh;
}