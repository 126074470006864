

input {
    width: 100%;
}

.form-group {
    width: 100%;
}

.input-color-added {
    background-color: #008945;
}