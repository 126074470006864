.text-right{
    text-align: right !important;
}
.text-left{
  text-align: left !important;
}
.step-container{
  margin-left: 20px;
  width: calc(100% - 60px);
}

/* Estilos para la tabla */
.custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  /* Estilos para las celdas de encabezado y cuerpo */
  .custom-table th, .custom-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  /* Estilos para las celdas de encabezado */
  .custom-table th {
    background-color: #008945;
    color: white;
    font-weight: bold;
  }
  
  /* Estilos para las filas de padres */
  .parent-row {
    background-color: #CCC;
  }
  
  /* Estilos para las filas de hijos */
  .child-row {
    background-color: #ffffff;
  }
  

  .mr-3{
    margin-right: 10px;
  }

  .box-shadow-none{
    box-shadow: none !important;
  }

  .w-100-px{
    width: 100px;
  }

  .color-black{
    color: black !important;
  }

  .bg-white{
    background-color: white !important;
  }

  .m-0{
    margin: 0 !important;
  }

  .m-step-3{
    margin: 0 0 0 auto !important;
  }

  .step-progress-container {
    display: flex;
    align-items: center;
    position: relative;
  }

  
  .step {
    flex: 1;
    text-align: center;
    position: relative;

    z-index: 2;

  }
  
  .step-number {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f2f2f2;
    margin: 0 auto 5px;
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .step span {
    font-size: 14px;
  }
  
  .step.active .step-number {
    background-color: #4CAF50; /* color del paso activo */
    color: white;
  }
  
  .progress-bar {
    flex: 4;
    height: 10px;
    background-color: #f2f2f2; /* color de fondo de la barra de progreso */
    position: absolute;
    left: 0;
    right: 0;
    bottom: 13px;
    z-index: 1;
  }
  
  .progress {
    height: 100%;
    background-color: #4CAF50; /* color de la barra de progreso */
    position: absolute;
    top: 0;
    left: 0;

    transition: width 2s;
  }
  
  .m-negative{
    margin-left: -20px;
  }

  .Spreadsheet__table, .Spreadsheet{
    width: 100%;
    max-height: 45vh;
    overflow: auto;
  }


  .search-printer{
    display: flex;
    gap: 5px;
  }

  .printers-container{
    height: 250px;
    overflow: auto;
    padding: 0 5px;
  }

  .printer-item { 
    position: relative;
    width: 100%;
    margin: 20px 0;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #ccc;
    transition: all 0.5s ease-in-out;
    padding: 1rem;
    display: flex;
  }

  .printer-item > p { 
    width: 45%;
  }

  .printer-item-list { 
    cursor: pointer;
  }

  .printer-item-list > p { 
    width: 100%;
  }

  .printer-item-list:hover { 
    background-color: #EFEFEF;
  }

  .remove-printer { 
    cursor: pointer;
    background-color: black;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }


  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 20px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #4caf50;
}

input:checked + .slider:before {
    transform: translateX(14px);
}
